import { notification } from 'antd';

export const toastTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
};

export function toast(type, message) {
  if (!message) return;
  notification[type]({
    message,
  });
}
