/**
 * Checks if a password value passes the complexity checks
 * Password must be at least 8 characters long
 * Password must contain characters from all of the following groups
 * Uppercase letters - all uppercase characters in the Basic Latin and the Latin-1 character sets
 * Lowercase letters - all lowercase characters in the Basic Latin and the Latin-1 character sets
 * Base 10 digits (0 through 9) and
 * Non-alphanumeric characters (special characters): ~!@#$%^&*_-+=`|\(){}[]:;"'<>,.?/
 * [PRD reference](https://fairmatic.atlassian.net/wiki/spaces/PM/pages/251691009/Q1-2023+OKR+PRD+for+password+complexity+check+for+Fairmatic+dashboard)
 * @param {string} value the password value to run through complexity checks
 */
export function checkPasswordComplexity(value = '') {
  const checks = [];

  // Password must be at least 8 characters long
  checks.minLength = value.length >= 8;

  // Password must contain an uppercase letter
  const uppercaseRegEx = /[A-Z]/g;
  checks.uppercaseLetter = !!value.match(uppercaseRegEx);

  // Password must contain a lowercase letter
  const lowercaseRegEx = /[a-z]/g;
  checks.lowercaseLetter = !!value.match(lowercaseRegEx);

  // Password must contain a number
  const numberRegEx = /[0-9]/g;
  checks.number = !!value.match(numberRegEx);

  // Password must contain a special character
  const specialCharacterRegEx = /[~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?\/]/g;
  checks.specialCharacter = !!value.match(specialCharacterRegEx);

  // Check if all checks were successful
  const success = Object.keys(checks).every((key) => checks[key]);

  return {
    success,
    checks: [
      'minLength',
      'lowercaseLetter',
      'uppercaseLetter',
      'number',
      'specialCharacter',
    ].map((key) => ({
      key,
      success: checks[key],
      message: {
        minLength: 'Minimum length should be 8 characters',
        uppercaseLetter: 'One upper case letter required',
        lowercaseLetter: 'One lower case letter required',
        number: 'Must contain a number',
        specialCharacter: 'Must contain a special character',
      }[key],
    })),
  };
}

export default { checkPasswordComplexity };
