import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { checkPasswordComplexity } from '../utils/password';

const PHONE_NUMBER_RULES = {
  phoneNumber: [
    {
      validator(_, value) {
        if (!value || isPossiblePhoneNumber(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Not a valid phone number'));
      },
    },
  ],
};

const SIGNUP_RULES = {
  firstName: [
    {
      required: true,
      message: 'Please enter the first name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid name',
    },
  ],
  lastName: [
    {
      required: true,
      message: 'Please enter the last name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid name',
    },
  ],
  email: [
    {
      required: true,
      message: 'Please enter the email',
    },
    {
      type: 'email',
      message: 'Please enter a valid email',
    },
  ],
  companyName: [
    {
      required: true,
      message: 'Please enter the company name',
    },
    {
      max: 20,
      message: 'Name cannot be more than 20 characters',
    },
    {
      type: 'string',
      message: 'Please enter a valid name',
    },
  ],
  ...PHONE_NUMBER_RULES,
};
const LOGIN_RULES = {
  email: [
    {
      required: true,
      message: 'Please enter the email',
    },
    {
      type: 'email',
      message: 'Please enter a valid email',
    },
  ],
};
const FORGOT_PASSWORD_RULES = {
  email: [
    {
      required: true,
      message: 'Please enter the email',
    },
    {
      type: 'email',
      message: 'Please enter a valid email',
    },
  ],
};
const UPDATE_PASSWORD_RULES = {
  newPassword: [
    {
      required: true,
      message: 'Enter your new password',
    },
    {
      min: 8,
      message: 'Password must have at least 8 characters',
    },
    {
      max: 64,
      message: 'Password can have at most 64 characters',
    },
    {
      pattern: /^[^\s].+[^\s]$/,
      message: 'Password cannot begin or end with whitespace',
    },
    {
      pattern: /^[\w~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?\/\ ]+$/,
      message: 'Password can only contain standard english keyboard characters',
    },
    {
      validator(_, value) {
        const { success } = checkPasswordComplexity(value);
        if (!success) {
          return Promise.reject(new Error('Password is not strong enough'));
        }
        return Promise.resolve();
      },
    },
  ],
  confirmPassword: [
    {
      required: true,
      message: 'Re-enter your password',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('newPassword') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Passwords do not match'));
      },
    }),
  ],
};
export {
  FORGOT_PASSWORD_RULES,
  LOGIN_RULES,
  PHONE_NUMBER_RULES,
  SIGNUP_RULES,
  UPDATE_PASSWORD_RULES,
};
